import React from 'react';
import {styled} from '@mui/system';

import {FIELD_TYPES} from "@ais/constants";
import {
  Checkbox,
  CLADatePicker,
  DropDown,
  Formula,
  FreeText,
  LongAnswer,
  QuestionGroup,
  RadioButtonList,
  ShortAnswer,
  Table
} from '@ais/forms';

import {FieldBuilder, Link} from '@components/ProjectForm';
import { useProjectFormContext } from "@contexts";

const StyledColumn = styled('div')(({width}) => ({
  display: 'flex',
	position: 'relative',
  padding: '24px',
  boxSizing: 'border-box',
  width: `${width}%`
}));

const StyledFieldWrapper = styled('div')({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  margin: '10px 0',
  width: '100%',
  padding: '8px'
});

export const FieldV2 = (props) => {
	const {field: {editableByClient, id, linkType, type, width}, handleSubmit} = props;
  const { answers} = useProjectFormContext();

  const disabled = linkType === 'Read Only' || !editableByClient;

  const renderFields = () => {
    switch (type) {
    case FIELD_TYPES.FREETEXT:
    case FIELD_TYPES.FREETEXT_NO_RULES:
    	return <FreeText {...props}/>;
    case FIELD_TYPES.SHORT_ANSWER:
      return (
        <ShortAnswer
          {...props}
          disabled={disabled}
          onSubmit={handleSubmit}
          onFocus={() => {}} //Temporary. To be filled by US133177 - Concurrency Implementation
        />
      );
    case FIELD_TYPES.LONG_ANSWER:
      return (
        <LongAnswer
          {...props}
          disabled={disabled}
          onSubmit={handleSubmit}
          onFocus={() => {}} //Temporary. To be filled by US133177 - Concurrency Implementation
        />
      );
    case FIELD_TYPES.RADIOGROUP:
      return (
        <RadioButtonList
          {...props}
          disabled={disabled}
          onSubmit={handleSubmit}
          onFocus={() => {}} //Temporary. To be filled by US133177 - Concurrency Implementation
        />
      );
    case FIELD_TYPES.DROPDOWN:
      return (
        <DropDown
          {...props}
          disabled={disabled}
          answerList={answers}
          onFocus={() => {}} //Temporary. To be filled by US133177 - Concurrency Implementation
          onSubmit={handleSubmit}
        />
      );
    case FIELD_TYPES.CHECKBOXGROUP:
      return (
        <Checkbox
          {...props}
          disabled={disabled}
          onSubmit={handleSubmit}
          onFocus={() => {}} //Temporary. To be filled by US133177 - Concurrency Implementation
        />
      );
    case FIELD_TYPES.DATE_PICKER:
      return (
        <CLADatePicker
          {...props}
          disabled={disabled}
          onSubmit={handleSubmit}
          onFocus={() => {}} //Temporary. To be filled by US133177 - Concurrency Implementation
        />
      );
    case FIELD_TYPES.FORMULA:
      return (
        <Formula 
          {...props} 
          disabled={disabled}
          answerList={answers}
        />
      );
    case FIELD_TYPES.TABLE:
      return (
        <Table
          {...props}
          disabled={disabled}
          onSubmit={handleSubmit}
          onFocus={() => {}} //Temporary. To be filled by US133177 - Concurrency Implementation
        />
      );
    case FIELD_TYPES.QUESTION_GROUP:
      return (
        <QuestionGroup
          {...props}
          disabled={disabled}
          onSubmit={handleSubmit}
          onFocus={() => {}} //Temporary. To be filled by US133177 - Concurrency Implementation
          fieldWrapper={FieldBuilder}
        />
      );
		default:
			return <></>;
    }
  };

  return (
    <StyledColumn width={width}>
      <StyledFieldWrapper>
        {renderFields()}
      </StyledFieldWrapper>
      <Link questionId={id} />
    </StyledColumn>
  );
};
