import api from "../utilities/api";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { defer, json } from "react-router-dom";

import { transformAnswers } from '@ais/utilities';

const fetchSharedForm = async () => {
    const { data } = await api.get("/client-user/project/shared-forms")
    return data;
}

export const sharedFormsQuery = () => ({
    queryKey: ["shared_forms"],
    queryFn: async () => fetchSharedForm()
})

export const useSharedForms = () => {
    return useQuery(sharedFormsQuery())
}

export const sharedFormsLoader = (queryClient) => async () => {
    try {
        const sharedForms = sharedFormsQuery();
        const sharedFormsData = await queryClient?.fetchQuery(sharedForms);
        return queryClient?.getQueryData(sharedForms) ?? defer({ data: sharedFormsData })
    } catch (error) {
        throw error.response ? json(error.response.data, error.response.status) : error
    }
}

const getUserById = async (userId) => {
    const { data } = await api.get(`/client-user/project-forms/users/${userId}`)
    return data;
}

export const getUserByIdQuery = (userId) => ({
    queryKey: ["userId", { userId }],
    queryFn: async () => getUserById(userId),
    enabled: !!userId,    
})

export const useUserInfo = (userId) => {
    return useQuery(getUserByIdQuery(userId))
}

const mapResponseToProjectFormInstance = (data) => {
    const {
      ProjectFormID,
      FormId,
      FormTypeId,
      ProjectFormName,
      FormDescription,
      ToolTipText,
      Units,
      MethodologyVersionId,
      MethodologyIndustries,
      ProjectFormSchemaID,
      Schema,
      RequiredSignOffLevel,
      IsClientAccessible,
      ClientShareDate,
      ClientDueDate,
      ClientCompletionDate,
      ClientProjectFormStatusId,
      Frameworks,
      PerformanceStandards,
      AuditAreas,
      Scotabds
    } = data;
    const projectForm = {
      projectFormID: ProjectFormID,
      projectFormName: ProjectFormName,
      formID: FormId,
      formTypeId: FormTypeId,
      formDescription: FormDescription,
      toolTipText: ToolTipText,
      units: Units,
      methodologyVersionId: MethodologyVersionId,
      methodologyIndustries: MethodologyIndustries,
      projectFormSchemaID: ProjectFormSchemaID,
      schema: Schema,
      requiredSignOffLevel: RequiredSignOffLevel,
      isClientAccessible: IsClientAccessible,
      clientShareDate: ClientShareDate,
      clientDueDate: ClientDueDate,
      clientCompletionDate: ClientCompletionDate,
      clientProjectFormStatusId: ClientProjectFormStatusId,
      frameworks: Frameworks,
      performanceStandards: PerformanceStandards,
      auditAreas: AuditAreas,
      scotabds: Scotabds
    };
    return projectForm;
  };

const PROJECT_FORM_QUERY_FACTORY = {
    all: [ "projectForm"],
    form: (projectFormId) => [ ...PROJECT_FORM_QUERY_FACTORY.all, "form", projectFormId ],
    answers: (projectFormId) => [ ...PROJECT_FORM_QUERY_FACTORY.all, "answers", projectFormId ],
    questionsLinked: (projectId, projectFormId) => [ ...PROJECT_FORM_QUERY_FACTORY.all, "questionsLinked", projectId, projectFormId ],
    relatedFields: (projectId, questionIds) => [ ...PROJECT_FORM_QUERY_FACTORY.all, "relatedFields", projectId, questionIds ]
}

export const useProjectFormSchema = (projectFormId) => {
    return useQuery(
        PROJECT_FORM_QUERY_FACTORY.form(projectFormId),
        async () => {
            const { data } = await api.get(`/client-user/project-form-with-schema-by-id/${projectFormId}`)
            return mapResponseToProjectFormInstance(data)
        },
        {
            enabled: !!projectFormId
        }
    )
}

export const useProjectAnswers = (projectFormId, isV2) => {
    return useQuery(
        PROJECT_FORM_QUERY_FACTORY.answers(projectFormId),
        async () => {
            const { data } = await api.get(`/client-user/all-project-answers/${projectFormId}`)
            return data
        },
        {
            enabled: !!projectFormId,
            select: (items) => {
                if (!isV2) {
                    const answerList = items.map((item) => ({
                        projectUnitAnswerId: item.ProjectUnitAnswerId,
                        projectId: item.ProjectId,
                        questionId: item.QuestionId,
                        answer: item.Answer,
                        lastUpdate: item.LastUpdate?.slice(0, 21).replace('T', ' ')
                    }));

                    return answerList.reduce((map, obj) => {
                        if (!obj.questionId) return map;
                        
                        map[obj.questionId.toLowerCase()] = obj.answer
                        return map;
                    }, {});
                }

                const newItems = Array.isArray(items) ? items : [items];

                const answerList = newItems.map((item) => ({
                    projectUnitAnswerId: item.ProjectUnitAnswerId,
                    projectId: item.ProjectId,
                    questionId: item.QuestionId,
                    answer: item.Answer,
                    lastUpdate: item.LastUpdate?.slice(0, 21).replace('T', ' ')
                }));
                return transformAnswers(answerList ?? []);
            }
        }
    )
}

export const useFormAnswersMutation = (projectId, projectFormId, isV2) => {
    const queryClient = useQueryClient()

    return useMutation(
        async (formData) => {
            const payload = {
                ProjectId: projectId,
                ProjectUnitId: formData.projectUnitIds,
                QuestionId: formData.questionId,
                Answer: formData.answer
            }
            const { data } = await api.post(`/client-user/project-unit-answer/${projectId}/${projectFormId}`, payload)
            return data
        },
        {
            onMutate: async (newAnswer) => {
                await queryClient.cancelQueries({ queryKey: PROJECT_FORM_QUERY_FACTORY.answers(projectFormId) })
                const previousTodos = queryClient.getQueryData(PROJECT_FORM_QUERY_FACTORY.answers(projectFormId))
                queryClient.setQueryData(PROJECT_FORM_QUERY_FACTORY.answers(projectFormId), (old) => {
                    if (isV2)
                        return old.find(p => p.QuestionId.toLowerCase() === newAnswer.questionId && (p.Answer = newAnswer.answer, true))
                    return [...old, newAnswer]
                })
                return { previousTodos }
            },
            onSettled: (result, variables, context) => {
                queryClient.invalidateQueries({ queryKey: PROJECT_FORM_QUERY_FACTORY.answers(projectFormId) })
            },
            onError: (error, variables, context) => {
                queryClient.setQueryData(PROJECT_FORM_QUERY_FACTORY.answers(projectFormId), context.previousTodos)
            }
        }
    )
}

export const useQuestionsWithLink = (projectId, projectFormId) => {
    return useQuery(
        PROJECT_FORM_QUERY_FACTORY.questionsLinked(projectId, projectFormId),
        async () => {
            const { data } = await api.get(`/client-user/project-forms/${projectId}/get-all-question-with-link/${projectFormId}`)
            return data
        },
        {
            enabled: !!projectId && !!projectFormId
        }
    )
}

export const useRelatedFieldsByQuestionIds = (projectId, questionId) => {
    return useQuery(
        PROJECT_FORM_QUERY_FACTORY.relatedFields(projectId, questionId),
        async () => {
            const { data } = await api.get(`/client-user/project-forms/${projectId}/get-all-related-fields/${questionId}`)
            return data
        },
        {
            enabled: !!projectId && !!questionId
        }
    )
}

export const useSubmitFormMutation = (projectFormId, projectId) => {
    return useMutation(
        async () => {
            const { data } = await api.put(`/client-user/projectforms/${projectFormId}/submit/${projectId}`);
            return data;
        }
    )
}