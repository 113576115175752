import { useEffect, useMemo } from 'react';
import { isEqual } from "lodash";
import { useForm } from "react-hook-form";
import { json, useLocation } from 'react-router-dom';

import { Form } from "@ais/palette";
import { isAnswerQuestionGroup, isAnswerTable, transformAnswersToPayload } from '@ais/utilities';

import { FormSection } from "@components/ProjectForm";
import { useProjectFormContext } from "@contexts";
import { useFormAnswersMutation } from "@services";
import logger from '@utilities/logService';

export const ProjectForm = ({ schema, answers }) => {
    const { projectId, projectFormId, projectForm } = useProjectFormContext();

    const {pathname} = useLocation(); //TEMPORARY. THIS SHOULD BE REMOVE ONCE V2 IS TESTED ALREADY!!!
    //TEMPORARY. THIS SHOULD BE REMOVE ONCE V2 IS TESTED ALREADY!!!
    const isV2 = useMemo(() => {
        return pathname.includes("v2/project/")
    }, [pathname]);

    const { mutate, error } = useFormAnswersMutation(projectId, projectFormId, isV2)
    const formMethods = useForm({
        defaultValues: {},
        values: answers
    });

    const getIdsWithUpdatedValues = (obj, source) =>
    Object.keys(source).filter(
        (key) =>
            !obj.hasOwnProperty(key) ||
            (obj.hasOwnProperty(key) && !isEqual(obj[key], source[key]))
    )

    const handleSubmitV2 = async (questionId, currentAnswers, answerList) => {
        const isQuestionGroup = isAnswerQuestionGroup(questionId);
        if(isQuestionGroup) questionId = questionId.split('.')[0];
        const answer = currentAnswers[questionId];
        const isTable = isAnswerTable(answer);
        if (answer === answerList[questionId]) return;

        const transformedValue = transformAnswersToPayload(answer, isTable, isQuestionGroup);
        
        if(isQuestionGroup || isTable)
            if(JSON.stringify(answer.map(ans => {
                const obj = {}
                for(const key in ans){
                    if(ans[key] !== null && ans[key] !== undefined) obj[key] = ans[key]
                }
                return obj
            })) === JSON.stringify(answerList[questionId])) return
        try {
            const payload = {
                projectId,
                projectUnitIds: projectForm.units.map((unit) => unit.ProjectUnitID),
                questionId,
                answer: transformedValue
            };
            mutate(payload);
        } catch (error) {
            logger.error(error.response.data.message ?? error.message);
            //Toast handles seperately via property state awareness of mutation Object
        }
    }


    const handleSubmit = (formValues, id) => {
        if (isV2) {
            handleSubmitV2(id, formValues, answers);
            return;
        }
        const updatedValues = getIdsWithUpdatedValues(answers, formValues)
        updatedValues.forEach((updatedValue) => {
            const updatedAnswer = formValues[updatedValue]
            if (updatedAnswer === null || updatedAnswer === undefined) {
                return
            }
            const payload = {
                projectId,
                projectUnitIds: projectForm.units.map((unit) => unit.ProjectUnitID),
                questionId: updatedValue,
                answer: updatedAnswer
            }
            mutate(payload)
        })
    }

    useEffect(() => {
        if (!error || !error.response) return;
        const { data, status } = error.response;

        throw json(data, status);
    }, [error])

    return (
        <Form form={formMethods} style={{ width: "100%" }}>
            <FormSection handleSubmit={formMethods.handleSubmit(handleSubmit)} schema={schema} />
        </Form>
    )
}

export default ProjectForm
