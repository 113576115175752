import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import { useLatest } from 'react-use';

import {
  CLANestedDataGrid, 
  auditAreaTableStyles,
  formatProjectInternalControl,
  formatICAuditAreaPayload,
  getRowClassName,
} from '@ais/components';
import { UNDERSTANDING_OF_ENTITY } from '@ais/constants';

import {
  useFetchProjectInternalControls,
  useUpdateFirstLevelInternalControlData, 
  useUpdateProjectScopeAuditAreaSCOTABDFields
} from '@services/internalControls';

import FinanceStatementProcess from './UOAuditAreaCollapsibles/FinanceStatementProcess';
import JournalEntries from './UOAuditAreaCollapsibles/JournalEntries';
import UOEScotabdsTable from './UOEScotabdsTable';
import './styles.css';
import { useICF } from '@providers/concurrency/hooks/useICF';

const UOEConstants = UNDERSTANDING_OF_ENTITY;
const UOEAuditAreasConstants = UOEConstants.AUDIT_AREAS;
const UOEAuditAreasColsConstants = UOEAuditAreasConstants.COLUMNS;

const UOEAuditAreaTable = () => {
  const { projectFormId, projectId } = useParams(); const [expandedRows, setExpandedRows] = useState([]);

  const {
    internalControlData: _internalControlData,
    currentInternalControlData,
    concurrencyEventReceived,
    handleInternalControlDataChanged,
    setConcurrencyEventReceived,
    setCurrentInternalControlData
  } = useICF();

  const latestInternalControlData = useLatest(currentInternalControlData);

  const handleAdditionalCollapseTrigger = (newIds) => {
    setExpandedRows(newIds);
  };

  const {
    data: internalControlData
  } = useFetchProjectInternalControls(projectFormId, projectId);

  const { mutate: updateFirstLevelInternalControlData } = useUpdateFirstLevelInternalControlData();
  const { mutate: updateProjectScopeAuditAreaSCOTABDFields } = useUpdateProjectScopeAuditAreaSCOTABDFields()

  useEffect(() => {
    if (!internalControlData || internalControlData?.length === 0) {
      setCurrentInternalControlData(null);
      return;
    }

    setCurrentInternalControlData(formatProjectInternalControl(internalControlData));
  }, [internalControlData]);

  useEffect(() => {
    if (concurrencyEventReceived) {
      handleInternalControlDataChanged(_internalControlData);
    }
  }, [concurrencyEventReceived, _internalControlData, currentInternalControlData])

  useEffect(() => {
    if (concurrencyEventReceived)
      setConcurrencyEventReceived(false)
  }, [currentInternalControlData])

  const saveInternalControlData = (newInternalControlData, latestInternalControlData) => {
    const payload = formatICAuditAreaPayload(newInternalControlData);
    updateFirstLevelInternalControlData(
      { projectFormId, projectId, payload: newInternalControlData },
      {
        onError: () => {
          setCurrentInternalControlData(latestInternalControlData);
        }
      }
    );
    setConcurrencyEventReceived(false)
  };

  const updateConcurrentInternalControl = (newInternalControlData) => {
    setCurrentInternalControlData(newInternalControlData);
    setConcurrencyEventReceived(false)
  }

  const auditAreasColumns = [
    {
      field: UOEAuditAreasColsConstants.AUDIT_AREAS.FIELD,
      headerName: UOEAuditAreasColsConstants.AUDIT_AREAS.HEADER_NAME,
      editable: false,
      sortable: false,
      filterable: false,
      maxWidth: 450,
      flex: 1,
      renderHeader: (params) => <span style={{ fontWeight: 1000 }}>{params.colDef?.headerName}</span>
    },
    {
      field: '',
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      flex: 1
    }
  ];


  const onSaveUpdateProjectScopeAuditAreaSCOTABDFields = (payload, newData) => {
    updateProjectScopeAuditAreaSCOTABDFields(
      { payload, projectFormId, projectId },
      {
        onSuccess: () => {
          setCurrentInternalControlData(newData); 
          setConcurrencyEventReceived(false)
        }
      }
    )
  }


  const auditAreas = currentInternalControlData?.ProjectScopeAuditArea?.map((auditArea) => auditArea) || [];

  const modifiedAuditAreas = useMemo(() => {
    if (!auditAreas || auditAreas.length === 0) return [];

    const sortedAuditAreas = sortBy(auditAreas, UOEAuditAreasConstants.DISPLAY_ORDER);

    return (
      sortedAuditAreas.map((item, index) => ({
        ...item,
        id: `AuditArea-${index}`,
        index,
        ExpandedPanel: (
          <UOEScotabdsTable
            auditAreaIndex={index}
            auditAreaId={item.AuditAreaId}
            scotabds={item.ProjectScopeAuditAreaSCOTABDS}
            projectScopeAuditAreaId={item.ProjectScopeAuditAreaId}
            currentInternalControlData={currentInternalControlData}
            latestInternalControlData={latestInternalControlData}
            updateCurrentInternalData={setCurrentInternalControlData}
            saveProjectScopeAuditAreaFields={onSaveUpdateProjectScopeAuditAreaSCOTABDFields}
          />
        )
      })) || []
    );
  });

  return (
    <>
      <CLANestedDataGrid
        sx={{
          ...auditAreaTableStyles,
          // When there are no auditAreas yet, do not show default NoRowsOverlay
          ...(!!currentInternalControlData &&
            modifiedAuditAreas?.length === 0 && {
            '& .MuiDataGrid-virtualScrollerContent': {
              display: 'none'
            },
            '& .MuiDataGrid-overlay': {
              display: 'none'
            }
          })
        }}
        columns={auditAreasColumns}
        rows={modifiedAuditAreas}
        expandedRow={expandedRows}
        rowHeight={76}
        getRowClassName={(params) =>
          getRowClassName(params, currentInternalControlData?.ProjectScopeAuditArea, expandedRows, 1)
        }
        handleAdditionalCollapseTrigger={handleAdditionalCollapseTrigger}
        hideFooter={true}
      />
      {!!currentInternalControlData && (
        <>
          <JournalEntries
            journalEntryInfoProcessing={currentInternalControlData?.JournalEntryICInfoProcessingAndControlActivity}
            currentInternalControlData={currentInternalControlData}
            latestInternalControlData={latestInternalControlData}
            saveInternalControlData={saveInternalControlData}
            updateConcurrentInternalControl={updateConcurrentInternalControl}
          />
          <FinanceStatementProcess
            financeStatementData={{
              FinancialStatementICInfoProcessingAndControlActivity1:
                currentInternalControlData?.FinancialStatementICInfoProcessingAndControlActivity1,
              FinancialStatementICInfoProcessingAndControlActivity2:
                currentInternalControlData?.FinancialStatementICInfoProcessingAndControlActivity2,
              FinancialStatementICInfoProcessingAndControlActivity3:
                currentInternalControlData?.FinancialStatementICInfoProcessingAndControlActivity3,
              FinancialStatementICInfoProcessingAndControlActivity4:
                currentInternalControlData?.FinancialStatementICInfoProcessingAndControlActivity4,
              FinancialStatementICInfoProcessingAndControlActivity5:
                currentInternalControlData?.FinancialStatementICInfoProcessingAndControlActivity5,
              FinancialStatementICInfoProcessingAndControlActivity6:
                currentInternalControlData?.FinancialStatementICInfoProcessingAndControlActivity6
            }}
            currentInternalControlData={currentInternalControlData}
            latestInternalControlData={latestInternalControlData}
            saveInternalControlData={saveInternalControlData}
            updateConcurrentInternalControl={updateConcurrentInternalControl}
          />
        </>
      )}
    </>
  );
};

export default UOEAuditAreaTable;