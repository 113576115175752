import { createContext, useContext, useMemo, useState } from "react"
import { useParams, useLocation } from "react-router-dom"
import { useProjectFormSchema, useProjectAnswers, useQuestionsWithLink, useSubmitFormMutation } from "@services";
import { QUESTION_LINK_TYPE } from "@ais/constants"

const ProjectFormContext = createContext()

export const ProjectFormContextProvider = ({ children }) => {
    const { projectId, projectFormId } = useParams();

    const {pathname} = useLocation(); //TEMPORARY. THIS SHOULD BE REMOVE ONCE V2 IS TESTED ALREADY!!!
    //TEMPORARY. THIS SHOULD BE REMOVE ONCE V2 IS TESTED ALREADY!!!
    const isV2 = useMemo(() => {
        return pathname.includes("v2/project/")
    }, [pathname])

    const { data: projectForm } = useProjectFormSchema(projectFormId);
    const { data: answers } = useProjectAnswers(projectFormId, isV2);
    const { data: linkedQuestions } = useQuestionsWithLink(projectId, projectFormId);
    const { mutateAsync: submitFormMutate } = useSubmitFormMutation(projectFormId, projectId);
    const [isQuestionGroupFocused, setIsQuestionGroupFocused] = useState(false);

    const linkDefaultList = useMemo(() => {
        if(!projectForm) {
            return []
        }
        const list = projectForm.schema.flatMap(section => section)?.flatMap(row => row.fields)?.flatMap(field => field)?.flatMap(field => (
            {
              id: field.id,
              isUserModified: !!field.isUserModified,
              existingQuestionId: field.existingQuestionId,
              linkType: field.linkType
            }
        ))?.filter(field => field.linkType === QUESTION_LINK_TYPE.QUESTION_LINK_TYPES[2])
        return list
    }, [ projectForm ]);

    const submitForm = async () => {
        await submitFormMutate();
    }

    const handleUpdateIsQuestionGroupFocused = (isQuestionGroupFocused) => {
        setIsQuestionGroupFocused(isQuestionGroupFocused);
      };

    return (
        <ProjectFormContext.Provider value={{ 
            projectId, 
            projectFormId, 
            projectForm,
            answers,
            linkedQuestions,
            linkDefaultList,
            isQuestionGroupFocused,
            actions: {
                submitForm,
                updateIsQuestionGroupFocused: handleUpdateIsQuestionGroupFocused
            }
        }}>
            { children }
        </ProjectFormContext.Provider>
    )
}

export const useProjectFormContext = () => {
    const ctx = useContext(ProjectFormContext)
    if(!ctx) {
        throw new Error("`useProjectFormContext` must be used within ProjectFormContextProvider")
    }
    return ctx
}
